export const setupTaskPageWS = async (task_id, configs) => {
  if (typeof window == "undefined") {
    return
  }

  // const baseurl = "http://localhost:4447"
  const baseurl = "/wsfe"

  // We have a few options:
  // a. One per topic domain:  /convs/
  // b. One per topic: /convs/convid
  // c. Or global - /events
  //
  // There are two factors to consider with each of the options:
  // Number of connecitons and routing choices
  //
  // If we did resource specific connections (eg /tasks/id) then this would cover an entire detail page with a single connection.  Here server side routing would need us
  // to manage which specific topics we care about.  Eg on the tasks page we may
  // be interested in a bunch of jobs - we could subscribe to specific jobids
  // or have a "*" and let server decide which ones to send
  // need us to 
  //
  // We could otherwise do it the "other way" and subscribe target specific streams
  // eg /jobs/jobid (and for all jobs), convs/tconv_taskid/ etc.  But then each
  // page has to decide which to connect to and then make N number of connections. 
  // This wil have minimal routings needed on server side and instead we are
  // placing load on the client to create the N connections.
  //
  // Problem with server side routing is that it gets harder to shard event sources
  // Eg if a task page needs events from job results and also updates from
  //
  // A general problem in all these areas is server vs client side routing of events
  // If we did topic domain based ones, say convs, then we need say task page
  // (or other resource specific conv) to connect to this convs channel and subscrbe
  // specific conns into it
  //
  // 
  // WE could do one ws endpoint "per" topic (eg /tasks/taskid/execs/, tasks/taskid/convs, tasks/taskid/updates etc
  // Or we could do 1 per resource:
  // tasks/taskid/*
  // or a bunch of global ones and push routing to server side
  //
  // Each has pros and cons
  // Option1 - topic 
  //
  //
  function handleTaskConvMessagePublished(msg) {
    console.log("setupTaskPageWS. Received conv message: ", msg);
    if ((msg?.Payload?.in_msg?.msg) && (getMessageType(msg.Payload.in_msg.msg) == 'comment')) {
      let messages_ref = window.dagknows?.messages_ref;
      if (typeof(messages_ref) != 'undefined') {
        const task_details_right_column_filter_state = useTaskDetailsRightColumnFilterState();
        const task_details_right_column_show_load_more_state = useTaskDetailsRightColumnShowLoadMoreState();

        if (! ['both','comment'].includes(task_details_right_column_filter_state.value)) {
          // If we receive a 'comment message', and we are looking at the 'job exec result tab', switch to 'both'
          // so that we can display the message
          task_details_right_column_filter_state.value = 'both'
        }
      
        messages_ref.value.push(msg.Payload.in_msg.msg);
      }
    }
  }

  async function handleTaskJobUpdated(msg) {
    if (typeof(window) != 'undefined') {
      if (! isAuthenticated()) {
        // If the user is not authenticaed, do not display execution result.
        return;
      }
    }

    if ((window.location.pathname.includes('/tasks/')) && (typeof($) == 'undefined')) {
      setTimeout(() => {
        handleTaskJobUpdated(msg)
      }, 1000);
      return;
    }

    console.log(`handleTaskJobUpdated invoked at ${(new Date()).toLocaleString()}`)

    let new_task_added = false;
  
    console.log("setupTaskPageWS. Received Job Update Message: ", msg);

    let is_copilot_page = isCopilotPage();
    const launchEditingMode = useLaunchEditingModeState();
    const agent_message_state = useAgentMessageState();
    const force_reload_code = useForceTaskReloadOnExceptionState();
    let add_empty_task_for_agent_page_state = useAddEmptyTaskForAgentPageState();
    let executing_task_index_state = useExecutingTaskIndexState();

    if ((typeof(msg) == 'undefined') || (msg == null) || (! msg)) {
      // I am not sure when this method would be invoked with undefined but when that 
      // happens (perhaps network condition, or something else happens in the backend),
      // the front-end should not result in a JavaScript error.  We should return early.
      return;
    }

    if ((typeof(window) != 'undefined') && (! window.location.pathname.includes('/tasks/'))) {
      // The user may have landed on the task details page, and therefore have setup a webhook 
      // subscription, but now the user had navigate away from the task details page.
      // We should not do anything here.  We should return early because the rest of the code 
      // inside this function assume that you are on the task details.  If we want to process 
      // webhook events when we are on other pages, we need to do something different perhaps 
      // by implementing that logic before we reach this point.
      return;
    }
    

    let message = msg;
    msg = msg.Payload
    console.log("setupTaskPageWS. ALTERED MSG: ", msg, message);
    const cmd = msg?.cmd
    const payload = msg?.payload

    const current_runbook_id = getTopRunbookTaskId();
    let source_type = message?.Name;
    let conv_id = '';
    let task_id = '';
    let index_path = '';
    let runbook_task_id = '';
    let job_state = '';
    let job_id = '';
    let curr_iter = '';
    let job_obj = {};
    let execres = {};

    if (typeof(payload) != 'undefined') {
      console.log(`setupTaskPageWS. GOT A PAYLOAD OBJECT: `, payload, message);
      curr_iter = payload.curr_iter;
      index_path = payload.index_path;
      job_id = payload.job_id;
      runbook_task_id = payload.runbook_task_id;
      task_id = payload.task_id;
      job_state = message?.Payload?.job?.status?.state || "";
    } else {
      conv_id = message?.Payload?.job?.conv_id || '';
      if ((conv_id == '') || (! conv_id.includes(current_runbook_id))) {
        return;
      }
  
      curr_iter = message?.Payload?.job?.status?.curr_iter;
      if (typeof(curr_iter) == 'undefined') {
        curr_iter = '';
      }
      //index_path = payload.index_path;

      runbook_task_id = message?.Payload?.job?.runbook_task_id || "";
      job_state = message?.Payload?.job?.status?.state || "";
      job_id = message?.Payload?.job?.id;
      task_id = message?.Payload?.execres?.task_id || '';
      index_path = message?.Payload?.execres?.index_path;   
      execres = message?.Payload?.execres;

      let index_path_to_start = message?.Payload?.job?.starting_child_path || "";
      let index_path_from_start = execres?.index_path || "";
      index_path = normalizedIndexPath(index_path_to_start, index_path_from_start);

      let debug_info = {
        curr_iter: curr_iter,
        job_id: job_id,
        conv_id: conv_id,
        task_id: task_id,
        index_path: index_path,
        runbook_task_id: runbook_task_id,
        job_state: job_state,
        source_type: source_type,
        message: message
      }

      job_obj = message?.Payload?.job;
  
      console.log(`setupTaskPageWS.handleTaskJobUpdated ${source_type}`, payload, debug_info);
  
    }

    if (is_copilot_page) {
      if (msg?.job?.status?.state == 'SUBMITTED') {
        /*
        if ((msg?.job?.special_param_values.hasOwnProperty('ai_agent_triggered')) && (msg?.job?.special_param_values.ai_agent_triggered)) {
        }
          */
        agent_message_state.value = "Executing task";
        launchEditingMode.value = msg?.job?.root_task_id;
        executing_task_index_state.value = msg?.job?.root_task_id;
        add_empty_task_for_agent_page_state.value = msg?.job?.root_task_id;
      }
    }

    // For the agent page, check the job status.  If it is done, and if this is the agent page, notify ExpandableTask to add a new empty 
    // child task for the next prompt box.
    if ((msg?.job?.status?.state == 'FINISHED') && (is_copilot_page)) {

      // When retry_count is 1 and there is an exception, it is pointless to reload the code because the 
      // backend is most likely still waiting on the LLM to generate new code, but after the first retry,
      // we want to reload the code regardless of whether there was any exception.  If there was no exception
      // during the retry, we should reload the code because that is the correct code.  If there was an 
      // exception during the retry, we should also reload the code because that is the latest code.
      if (msg?.job?.special_param_values?.retry_count > 1) {
        let root_task_id = msg.job.root_task_id
        force_reload_code.value = root_task_id;
      }

      if (msg?.job?.ai_done) {
        launchEditingMode.value = "";
        // Hide the "Executing task" message
        if ((msg?.job?.ai_done_reason == 'Giving up') || (msg?.job?.ai_done_reason == 'Done successfully')) {
          agent_message_state.value = "";
        } else if (msg?.job?.ai_done_reason == 'Continue to next') {
          // Can I fabricate a fake notifyJobListeners when we encounter "code_type": "none" ?
          agent_message_state.value = "Generating task";
        }

        // Display the latest output / plot in case there was an exception, and the backend was able to 
        // resolve the exceptions without reaching the max retry count.  We need this because we did not 
        // want to display exceptions unless it is the last exception

        if (msg?.job?.special_param_values?.retry_count > 1) {
          task_id = msg?.job?.root_task_id;
          let correct_index_path = "";
          document.querySelectorAll('.hidden_task_id').forEach((elem) => {
            if (elem.innerText.trim() == task_id) {
              let step_number = elem.previousSibling.innerText.trim();
              correct_index_path = buildStartingChildPath(step_number);
            }
          });
          if (correct_index_path) {
            codeelem = getIndexPathElem("", runbook_task_id, correct_index_path);
            let cached_output = getCachedOutput(task_id, "regular");
            codeelem.innerText = cached_output;
  
            const containing_element = getIndexPathElem("plot_", runbook_task_id, correct_index_path);
            let cached_plot = getCachedOutput(task_id, "plot");
            containing_element.innerHTML = cached_plot;
          }  
        }
      } else {
        // msg.job.special_param_values.ai_agent_triggered
        if (! msg?.job?.special_param_values.hasOwnProperty('ai_agent_triggered')) {
          agent_message_state.value = "";
        }
      }
      add_empty_task_for_agent_page_state.value = msg.job.root_task_id;
    }

    if ((msg?.job?.status?.state == 'FINISHED') && (is_copilot_page)) {
      if (msg?.job?.ai_done) {
        /*
        For the agent page, when the job is finished, we notify the frontend twice.  
        Inside app.py, we call self.jobsclient.UpdateJobStatus, and a few line down, we have "jobFinished", and we have additional logic for 
        handling "AI jobs" (retry and continue to next, etc).  Inside UpdateJobStatus we do the normal notifyJobListeners, but in the jobFinished
        block, we need to call notifyJobListeners again with ai_done and ai_done_reason, so that the frontend can do the appropriate thing (hiding 
        the "Generating task" and "Executing task", and rerender the task if it was retried successfully without reaching the max-retry).

        Perhaps one way to avoid sending duplicate notification is to move the "jobFinish" block to inside UpdateJobStatus.
        Perhaps another way to avoid sending duplicate notification is to move the UpdateJobStatus call down below the "jobFinish" block, and pass
        the ai_done, and ai_done_reason as additional parameters to UpdateJobStatus somehow, and have these publish to the front-end.

        When we are able to avoid this duplicate notification, we need to remove the return statement below.
        */

        return;
      }
    }

    // Handle updating the right panel (job cards)
    if ((job_id != '') && (Object.keys(job_obj).length > 0)) {
      const dayjs = useDayjs();
      let job = job_obj;

      const task_details_right_column_filter_state = useTaskDetailsRightColumnFilterState();
      const task_details_right_column_show_load_more_state = useTaskDetailsRightColumnShowLoadMoreState();

      let obj = {};
      obj['req'] = 'job_info';
      obj['job_id'] = job_id;
      obj['id'] = 'job_' + job_id; // This 'id' here is the ID of the message
      obj['user'] = job['user_info'];
      obj['tstp'] = (new Date()).getTime() / 1000;
      obj['task_param_values'] = job['param_values'];
      obj['param_values'] = job['param_values'];
      obj['schedule'] = job['schedule'];
      obj['special_param_values'] = job['special_param_values'];
      obj['starting_child_path'] = job['starting_child_path'];

      delete job['proxy_token'];
      delete job['role_token'];

      obj['job'] = job;
      if (obj['job'].hasOwnProperty('iter_statuses')) {
        let iter_statuses = obj['job']['iter_statuses'];
        let latest_iter = 0;
        for (let iter in iter_statuses) {
          latest_iter = Math.max(latest_iter, iter);
        }
        obj['latest_iter'] = latest_iter;
      }
      let messages_ref = window.dagknows?.messages_ref;
      if (typeof(messages_ref) != 'undefined') {
        if (task_details_right_column_filter_state.value == 'comment') {
          // If we receive a 'job message', and we are looking at the 'comment' tab, switch to 'both' 
          // so that we can display the message
          task_details_right_column_filter_state.value = 'both'
        }

        let start_index = Math.max(messages_ref.value.length - 100, 0)
        let existed = messages_ref.value.slice(start_index).some((element) => {
          return element.job_id === job_id;
        });

        console.log(`setupTaskPageWS. Websocket message received for JOB ID ${obj['job_id']}`, obj)

        if (! existed) {
          console.log("setupTaskPageWS. NOT EXISTED IN messages_ref.  ADDING IT TO messages_ref.")
          messages_ref.value.push(obj);
          setTimeout(function () {
            const el = document.querySelector('.comments_container') || null
            if (el != null) el.scrollTop = el.scrollHeight;
          }, 1000);        
        } else {
          console.log("setupTaskPageWS. EXISTED IN messages_ref.  CALLING updateJobDiv.")
          updateJobDiv(obj, dayjs, 'job_info', true, existed);
        }

        track_iterations(obj, 'job_info');
      }
    }

    // Handle updating the left panel

    let update = shouldUpdate(job_id, curr_iter, false, msg?.job?.status?.state);
    if (is_copilot_page) {
      update = true;
    }
    if (! update) {
      return;
    }

    let codeelem = null;    

    if (! window.hasOwnProperty('dagknows')) {
      window['dagknows'] = {};
    }

    // Prevent iterations from clobbering the result of each other 
    let previous_iteration = window.dagknows?.current_iteration || '';
    let previous_job_id = window.dagknows?.current_job_id || '';
    if ((previous_iteration != curr_iter) || (previous_job_id !== job_id)) {
      if ((typeof(job_state) != 'undefined') && (job_state != 'FINISHED')) {
        if (! is_copilot_page) {
          hidePreviousExecutionResult();
        }
      }
      window.dagknows.current_iteration = curr_iter;
      window.dagknows.current_job_id = job_id;
    }

    // Handle plotting
    if (execres?.extras?.plots) {
      const plotelem = getIndexPathElem("plot_", runbook_task_id, index_path);

      if ((window.location.pathname.includes('/tasks/')) && (plotelem == null)) {
        // Can this be because the left side is still being rendered, or re-rendered?
        if (is_copilot_page) {
          // Or it can be that backend has gone on to add a sub-task which we currently do 
          // not have a mechanism to notify the front-end yet.  So, when we see a task that 
          // is not on the page yet, we need to determine its parent task ID, and tell the 
          // parent task to re-render, which would render any missing child tasks.
          await tellParentTaskToUpdate(task_id)
        }

        setTimeout(() => {
          handleTaskJobUpdated(message)
        }, 500);
        return;
      }
  
      const plots = execres.extras.plots || []
      if (plots.length > 0) {
        const plot = plots[0]
        for (var i = 0 ; i < plot.traces.length;i++) {
          plot.traces[i]["type"] = plot.traces[i]["type"] || "lines"
        }
        plotGraphs(plot.title, plot.xlabel, plot.ylabel, plot.traces, plotelem);
        if ((is_copilot_page) && (task_id) && (plotelem != null)) {
          cacheLastOutput(task_id, 'plot', plotelem.innerHTML)
        }
      }  
    }

    // Handle displaying tables, blocks
    if ((typeof(execres?.extras?.blocks) != 'undefined') ) {
      const containing_element = getIndexPathElem("plot_", runbook_task_id, index_path);
      // Get ready to render the array of blocks
      // We have to reset.  We do not want the blocks to be repeated multiple times if we receive 
      // multiple events.  We haven't really discuss how we can stream and add  data to an 
      // existing table yet.
      if ((window.location.pathname.includes('/tasks/')) && (containing_element == null)) {
        // Can this be because the left side is still being rendered, or re-rendered?
        if (is_copilot_page) {
          // Or it can be that backend has gone on to add a sub-task which we currently do 
          // not have a mechanism to notify the front-end yet.  So, when we see a task that 
          // is not on the page yet, we need to determine its parent task ID, and tell the 
          // parent task to re-render, which would render any missing child tasks.
          await tellParentTaskToUpdate(task_id)
        }
        setTimeout(() => {
          handleTaskJobUpdated(message)
        }, 500);
        return;
      }

      containing_element.innerHTML = '';

      const blocks = execres?.extras?.blocks || [];
      renderBlocks(blocks, containing_element);
      if ((is_copilot_page) && (task_id) && (containing_element != null)) {
        cacheLastOutput(task_id, 'plot', containing_element.innerHTML)
      }
    }

    if (cmd == "print") {
      const starting_task_id = payload.starting_task_id
      const task_id = payload.task_id

      const index_path_to_start = payload.starting_child_path || ""
      const index_path_from_start = payload.index_path || ""
      const index_path = normalizedIndexPath(index_path_to_start, index_path_from_start)
  
      console.log("setupTaskPageWS. Msg: ", msg)
      console.log(`setupTaskPageWS. MsgId: ${msg.msgid}, RootTaskId: ${runbook_task_id}, TaskId: ${task_id}, StartingTaskId: ${starting_task_id}, IndexPath: ${index_path}, PRINT ${payload.stream}: `, payload.args)

      codeelem = getIndexPathElem("", runbook_task_id, index_path);
      if (codeelem != null) {
        if (codeelem.innerText.trim() == '') {
          codeelem.innerText = payload.args.join(" ");
        } else {
          codeelem.innerText += "\n" + payload.args.join(" ");
        }

        codeelem.parentElement.scrollTop = codeelem.parentElement.scrollHeight
      }  

    } else if ((typeof(execres) != 'undefined') && (execres != null) && (Object.keys(execres).length > 0)) { // end of cmd == "print"
      console.log("setupTaskPageWS. SOURCE_TYPE: ", source_type, message)
      if ((source_type == 'tasks.jobs.updated') || (source_type == 'runbooks.tasks.execs.updated')) {
        const index_path_to_start = message?.Payload?.job?.starting_child_path || ""
        const index_path_from_start = execres.index_path || ""
        const index_path = normalizedIndexPath(index_path_to_start, index_path_from_start)
        codeelem = getIndexPathElem("", runbook_task_id, index_path);
        if (codeelem != null) {
          let stdall = execres?.stdall || "";

          if (typeof(execres?.exceptions) != 'undefined') {
            const exceptions = (execres.exceptions || []).map(e =>  e.msg)
            if (exceptions.length > 0) {
              stdall += exceptions.join('\n');
            }  
          }
    
          if ((is_copilot_page) && ((execres?.exceptions?.length > 0) || (execres?.stderr?.trim() != ''))) {
            // On the agent page, if there is exception or error, we do not want to display that, unless it reach 
            // the maximum retry_count
            codeelem.innerText = "";  // I think, so far, we have not display the exception or anything, and perhaps it is ok to set it to empty.
            
            if ((msg.job.special_param_values.ai_agent_triggered) && (msg.job.special_param_values.retry_count >= 5)) {

              // Show the "problem that LLM cannot solve message"
              const agent_error_elem = getIndexPathElem("agent_error_", runbook_task_id, index_path);
              if (agent_error_elem) {
                $(agent_error_elem).show();
                agent_error_elem.classList.remove("d-none");
              }

              // Show the exception
              codeelem.innerText = stdall.trim();
              codeelem.parentElement.scrollTop = codeelem.parentElement.scrollHeight;
              // Cache the display so that we can rerender the display later when the user move the task around
              if ((is_copilot_page) && (task_id) && (codeelem != null)) {
                cacheLastOutput(task_id, 'regular', stdall.trim())
              }

              // Hide the "Executing task" message
              agent_message_state.value = "";
            } else {
              // Cache the exception so that when we receive 'ai_done' and it has not exceed the max retry_count
              if ((is_copilot_page) && (task_id) && (codeelem != null)) {
                cacheLastOutput(task_id, 'regular', stdall.trim())
            }
            }
          } else if (stdall.trim() != '') {
            codeelem.innerText = stdall.trim();
            // Cache the display so that we can rerender the display later when the user move the task around
            if ((is_copilot_page) && (task_id) && (codeelem != null)) {
              cacheLastOutput(task_id, 'regular', stdall.trim())
            }
            codeelem.parentElement.scrollTop = codeelem.parentElement.scrollHeight;
          } else {
            if (is_copilot_page) {
              // codeelem.innerText = "There was no output produced.";
              codeelem.innerText = "";
            }
            if ((is_copilot_page) && (task_id) && (codeelem != null)) {
              cacheLastOutput(task_id, 'regular', codeelem.innerText);
            }
          }
      
        } else {
          let x = runbook_task_id + '_' + index_path;
          console.log(`setupTaskPageWS.  LOOKING FOR ${x} NOT FOUND.`)
          if (is_copilot_page) {
            // Or it can be that backend has gone on to add a sub-task which we currently do 
            // not have a mechanism to notify the front-end yet.  So, when we see a task that 
            // is not on the page yet, we need to determine its parent task ID, and tell the 
            // parent task to re-render, which would render any missing child tasks.
            tellParentTaskToUpdate(task_id)
          }
          setTimeout(() => {
            handleTaskJobUpdated(message)
          }, 500);
          return;
        }
      }
    } else {
      console.log("setupTaskPageWS. MESSAGE OTHER:", message)
    }

    // I believe that the following codeelem if statement does not have anything to do with the agent page.
    // It is for the regular task page.  If the codeelem contains content, it decides whether to show or 
    // hide the caret (triangle) if the content of the codeelem overflow (has scrollbar).  If the content 
    // does not overflow, it hide the caret.  Then it call scrollIntoView to bring the codeelem into 
    // the view port so that the user can see the output of the execution.
    if (codeelem != null) {
      if (codeelem.innerText.trim().search(/\S+/) > -1) {
        codeelem.parentElement.classList.add('left_side_execution_result_with_padding_and_border');
        let hasVerticalScrollbar = codeelem.parentElement.scrollHeight > codeelem.parentElement.clientHeight;
        if (hasVerticalScrollbar) {
          codeelem.parentElement.nextSibling.style.display = 'block';
        } else {
          codeelem.parentElement.nextSibling.style.display = 'none';
        }
      } else {
        codeelem.parentElement.classList.remove('left_side_execution_result_with_padding_and_border');
      }
      try {
        codeelem.parentElement.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      } catch (ex) {
        console.warn(ex);
      }
    }

    let log_levels = [];
    let log_level = '';
  
    const logs = execres?.extras?.logs || [];  // We need to determine where we can get this information from.
    logs.forEach((log_object) => {
      log_levels.push(log_object.level.toLowerCase())
    })
  
    if (log_levels.includes('error')) {
      log_level = 'error';
    } else if (log_levels.includes('warning')) {
      log_level = 'warning'
    } else if (log_levels.includes('success')) {
      log_level = 'success'
    }
  
    if (log_level != '') {
      let css_class = 'left_side_execution_result_' + log_level;
      if (codeelem != null) {
        let task_el = findAncestorWithClass(codeelem, 'title_row')
        task_el.classList.add(css_class);
      }
    }


    // Handle updating the orange bar
    if (task_id != '') {
      let element = getIndexPathElem('task_container_', runbook_task_id, index_path);
      if ((element != null) && (! element.classList.contains('executing_task'))) {
        // If this element does not contains the executing_task, then we must remove the 
        // executing_task class from other elements / tasks, and then add this CSS class 
        // to this task

        $('.executing_task').removeClass('executing_task'); // remove this class from previous executing task

        element.classList.add('executing_task');
        if (! is_copilot_page) {
          // Regular task detail page, old behavior
          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });  
        } else {
          // Agent page, always scroll down but never scroll up
          let space_holder_elem = document.querySelector('.agent_page_space_holder_bottom');
          if (! isElementInViewport(space_holder_elem)) {
            space_holder_elem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });  
          }
        }
      }
    }
    // If the job is finished, remove the orange bar
    if (job_state == 'FINISHED') {
      $('.executing_task').removeClass('executing_task'); // The job is finished
    }

    // Handle updating the input / output parameters
    console.log(`TRYING TO UPDATE INPUT / OUTPUT PARAMETERS.  msg:`, msg)
    if ((typeof(execres) != 'undefined') && (Object.keys(execres).length > 0)) {
      displayInputOutput(runbook_task_id, index_path, execres);
    }
  }

  function setupWS() {
    if (typeof(window) != 'undefined') {
      if (! isAuthenticated()) {
        // If the user is not authenticaed, do not display execution result.
        return;
      }
    }
  
    const wsconn = newWSConn(`${baseurl}/hub/connect`)
    wsconn.subscribe("tasks.jobs.updated", {"sources": [task_id]}, handleTaskJobUpdated)
    wsconn.subscribe("runbooks.tasks.execs.updated", {"sources": [task_id]}, handleTaskJobUpdated)
    wsconn.subscribe("convs.messages.published", {"sources": [`tconv_${task_id}`]}, handleTaskConvMessagePublished)
    wsconn.start()
  }

  /*
  function setupExecsWS() {
    const wsconn = newWSConn(`${baseurl}/tasks/${task_id}/execs/viewer/connect`)
    // wsconn.subscribe("/taskexecs/" + task_id)
    wsconn.onMessage = (msg) => {
      const cmd = msg.cmd
      const payload = msg.payload
      if (cmd == "print") {
        const runbook_task_id = payload.runbook_task_id || ""
        const starting_task_id = payload.starting_task_id
        const task_id = payload.task_id
        const curr_iter = payload.curr_iter
        const index_path_to_start = payload.starting_child_path || ""
        const index_path_from_start = payload.index_path || ""
        const index_path = normalizedIndexPath(index_path_to_start, index_path_from_start)

        if (runbook_task_id == "") {
          console.log("Here - how do we find runbook task id?")
        }

        console.log("Msg: ", msg)
        console.log(`MsgId: ${msg.msgid}, RootTaskId: ${runbook_task_id}, TaskId: ${task_id}, StartingTaskId: ${starting_task_id}, IndexPath: ${index_path}, PRINT ${payload.stream}: `, payload.args)

        const codeelem = getIndexPathElem("", runbook_task_id, index_path);
        if (codeelem != null) {
          codeelem.innerHTML += "<br/>" + payload.args.join(" ")
          codeelem.parentElement.scrollTop = codeelem.parentElement.scrollHeight
        }
      }
    }
    wsconn.start()
  }

  function setupConvsWS() {
    const wsconn = newWSConn(`${baseurl}/tasks/${task_id}/convs/connect`)
    // wsconn.subscribe("/taskexecs/" + task_id)
    wsconn.onMessage = (msg) => {
      const cmd = msg.cmd
      const payload = msg.payload
      if (cmd != "ping") {
        console.log("Got Conv Msg: ", payload)
      }
    }
    wsconn.start()
  }
  */

  setTimeout(async function() { setupWS() }, 200);
  // setTimeout(async function() { setupExecsWS() }, 200);
}
